import {Toast} from "vant";

export default {
    //微信内部支付
    pay(detail) {
        function onBridgeReady() {
            WeixinJSBridge.invoke(
                "getBrandWCPayRequest",
                {
                    appId: detail['appId'], //公众号ID，由商户传入
                    timeStamp: detail['timeStamp'] + "", //时间戳，自1970年以来的秒数
                    nonceStr: detail['nonceStr'], //随机串
                    package: detail['package'],
                    signType: detail['signType'], //微信签名方式：
                    paySign: detail['paySign'], //微信签名
                },
                function (res) {
                    if (res.err_msg === "get_brand_wcpay_request:ok") {
                        // 使用以上方式判断前端返回,微信团队郑重提示：
                        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                        Toast('支付成功，请刷新页面兑换');
                    }
                }
            );
        }

        if (typeof WeixinJSBridge === "undefined") {
            if (document.addEventListener) {
                document.addEventListener(
                    "WeixinJSBridgeReady",
                    onBridgeReady(),
                    false
                );
            } else if (document.attachEvent) {
                document.attachEvent("WeixinJSBridgeReady", onBridgeReady());
                document.attachEvent("onWeixinJSBridgeReady", onBridgeReady());
            }
        } else {
            onBridgeReady();
        }
    }
}
