import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        //后台VUE配置
        vueConfig: {
            id: null,
            index: null,
            username: null,
            vipGroupBatch: null,
            title: null,
            bgImg: null,
            kefuUrl: null,
            buyUrl: null,
            wechatPay: null,
            iconType: null,
            codeValidate: null,
            enterMessage: null,
            note: null
        },
        code: null,
        token: sessionStorage.getItem("token") || '',
        eligibilityId: '',
        phone: '',
    },
    mutations: {
        setVueConfig(state, data) {
            state.vueConfig = data;
        },
        setCode(state, data) {
            state.code = data;
        },
        setToken(state, data) {
            state.token = data;
            sessionStorage.setItem("token", data);
        },
        setEligibilityId(state, data) {
            state.eligibilityId = data;
        },
        setPhone(state, data) {
            state.phone = data;
        }
    },
    actions: {
        asyncSetVueConfig(context, data) {
            context.commit('setVueConfig', data);
        },
        asyncSetCode(context, data) {
            context.commit('setCode', data);
        },
        asyncSetToken(context, data) {
            context.commit('setToken', data);
        },
        asyncSetEligibilityId(context, data) {
            context.commit('setEligibilityId', data);
        },
        asyncSetPhone(context, data) {
            context.commit('setPhone', data);
        }
    },
    modules: {}
})
