import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import "../public/reset.css"
import 'vant/lib/index.css';
import Vant from 'vant';
Vue.use(VueAxios,axios);
Vue.use(Vant);

/*import VConsole from 'vconsole';
const vConsole = new VConsole();
Vue.use(vConsole);
console.log("");*/
Vue.config.productionTip = false;
// axios.defaults.baseURL = "/proxy";

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
